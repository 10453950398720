<template>
  <div class="fd-w-full fd-bg-theme fd-py-3 fd-px-5" style="height: 650px">
    <div class="fd-flex fd-items-center fd-justify-between">
      <span class="fd-text-lg fd-font-bold fd-text-theme-2">{{ $t('media.3DView') }}</span>
      <b-button variant="outline-primary" @click="openBrowser">
        <div class="fd-flex fd-items-center">
          <feather-icon icon="PlusIcon" size="12" />
          <span class="fd-block fd-ml-3 fd-text-sm fd-font-medium">{{ $t('global.upload') }}</span>
        </div>
      </b-button>
      <input type="file" ref="file" @change="selectFile" style="display: none">
    </div>
    <div v-if="threeDView && !image.blob" class="fd-w-full fd-mt-4 fd-relative" style="background-color: #DCDCDC; height: calc(100% - 55px);">
      <img :src="threeDView" alt="3d view">
    </div>
    <div v-else-if="!image.blob" class="fd-w-full fd-mt-4 fd-relative" style="background-color: #DCDCDC; height: calc(100% - 55px);">
      <span style="position:absolute; left: 50%; top: 50%; transform: translate(-50%, -50%)">
        <SvgLoader :name="'3dcube-2'" />
      </span>
    </div>
    <div v-else class="fd-w-full fd-mt-4 fd-relative" style="height: calc(100% - 55px);">
      <div v-if="image.progress < 100" class="fd-absolute" style="top: 0; left: 0; width: 100%; height: 100%; background-color: rgba(0,0,0, .7)">
        <div class="fd-w-3/4 fd-mx-auto" style="position:absolute; top: 50%; left: 50%; transform: translate(-50%, -50%)">
          <b-progress :value="image.progress" :max="100" show-progress animated class="progress-bar-success"></b-progress>
        </div>
      </div>
      <img :src="image.blob" alt="">
    </div>
    <b-toast id="example-toast" title="BootstrapVue" static></b-toast>
  </div>
</template>

<script>
import { BButton, BToast, BProgress } from 'bootstrap-vue'
import BaseService from "@/http/baseService";

export default {
  name: "ThreeDView",
  components: {BButton, BToast, BProgress},
  data() {
    return {
      selectedFile: null,
      name: null,
      type: null,
      baseUrl: null,
      image: {
        blob: null,
        progress: 0
      },
    }
  },
  created() {
    this.baseUrl = BaseService.getInstance().getBaseUrl()
    this.getData()
  },
  watch: {
    selectedFile: {
      handler() {
        if (!this.selectedFile) return
        const fileName = this.selectedFile.name.split('.')
        this.name = fileName[0]
        this.type = '3d_view'

        let formData = new FormData()
        formData.append('media', this.selectedFile)
        formData.append('name', this.name)
        formData.append('type', this.type)

        let URL = window.URL || window.webkitURL
        this.image = {
          blob: URL.createObjectURL(this.selectedFile),
          progress: 0
        }

        const config = {
          onUploadProgress: progressEvent => {
            let percentComplete = progressEvent.loaded / progressEvent.total
            this.image.progress = parseInt(percentComplete * 100);
          }
        }

        this.$store.dispatch('project/uploadFile', {
          data: formData,
          url: this.baseUrl + `/complexes/${this.$route.params.id}/media`,
          config
        })
          .then(() => {
            this.getData()
          })
          .catch(() => {
            this.image = {
              blob: null,
              progress: 0
            }
            this.makeToast()
          })
          .finally(() => {
            this.selectedFile = null
          })
      },
      deep: true
    },
  },
  computed: {
    threeDView() {
      return this.$store.getters['complex/complexThreeDView']
    }
  },
  methods: {
    getData() {
      this.$store.dispatch('complex/getComplexThreeDView', this.$route.params.id)
    },

    openBrowser() {
      this.$refs.file.click()
    },

    selectFile() {
      this.selectedFile = this.$refs.file.files[0]
      this.$refs.file.value = ''
      // this.newImageUrl = URL.createObjectURL(this.image)
    },

    makeToast() {
      this.$bvToast.toast('Toast body content', {
        title: 'Error',
        toaster: 'b-toaster-top-right',
        variant: 'danger',
        solid: true
      })
    },
  }
}
</script>

<style scoped>

</style>